import styles from "./button.module.css";
import googleIcon from "../../../../assets/google-icon.svg";
// import appleIcon from "../../../../assets/apple-icon.svg";
import { auth } from "../../../../utils/firebase";
import { useOnboardingContext } from "../../../../context/onboarding-context";
import {
  onAuthStateChanged,
  signInWithPopup,
  GoogleAuthProvider,
} from "firebase/auth";
import { useEffect } from "react";
import { fbg } from "../../../../utils/fbg";

export const AuthButtons = () => {
  const { result, setResult, setStep, step, data } = useOnboardingContext();
  // const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        const email = user.email;

        if (email) {
          setResult({ ...result, email: [user.email] });
          fbg("track", "CompleteRegistration");

          if (data.pages.length - 1 > step) {
            setStep(step + 1);
          } else {
            const search = document.location.search || "";
            document.location.href = "/paywall" + search;
          }
        }
      }
    });
  }, []);

  const onAuth = () => {
    const AuthProvider = new GoogleAuthProvider();
    signInWithPopup(auth, AuthProvider);
  };

  return (
    <div>
      <div className={styles.divider}>
        <span>OR</span>
      </div>
      <div className={styles.auth_buttons}>
        <button className={styles.button} onClick={onAuth}>
          <img src={googleIcon} alt="google" />
          Continue with Google
        </button>
        {/* {isSafari && (
          <button className={styles.button} onClick={onAuth}>
            <img src={appleIcon} alt="apple" />
            Continue with Apple
          </button>
        )} */}
      </div>
    </div>
  );
};
