import { Button } from "../common-parts/button";
import style from "./styles.module.css";
import { CountDown } from "./countdown";
import { getPaymentDataOffer } from "./data/offerData";
import { useEffect, useState } from "react";
import * as amplitude from "@amplitude/analytics-browser";
import { fbg } from "../../utils/fbg";
import { Circle } from "../common-parts/circle";
import { useReadLocalStorage } from "usehooks-ts";
import { apiClient } from "../../utils/api-client";
import { Layout } from "../common-parts/layout";
import { Footer } from "../common-parts/footer";
import { useGetQueryParams } from "../../hooks/useGetQueryParams";
import CloseIcon from "../../assets/close-icon.svg";
import { productIds } from "../../data/paymentData";

export const SpecialOffer = () => {
  const [isLoad, setIsLoad] = useState(false);
  const searchParams = new URLSearchParams(window.location.search);
  const source =
    searchParams.get("canceled") === "true" ? "purchase_failed" : "onboarding";
  const onboardingData =
    useReadLocalStorage<{ [key in string]: string[] }>("onboarding");
  const queryParams = useGetQueryParams();
  const isWeb = searchParams.get("dest") === "web";
  const closeUrl = isWeb
    ? "https://web.nufa.ai"
    : "https://nufa.onelink.me/OSCY/rvrapu8n";

  useEffect(() => {
    amplitude.track("show_paywall_screen", {
      paywall: "special_offer",
      source,
    });
  }, []);

  const handlerClick = () => {
    setIsLoad(true);

    const paymentData = getPaymentDataOffer();

    amplitude.track("paywall_purchase_tap", {
      id: paymentData.productId,
    });

    const metadata: { [key in string]: string | number | boolean } = {
      amplitude: amplitude.getDeviceId() || "",
    };

    apiClient("/api/v1/stripe/session", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        price_id: paymentData.priceId,
        email: onboardingData?.["email"][0],
        subscription_details: {
          metadata,
        },
        success_url: `${window.location.origin}/success?product_id=${productIds[2].productId}&special-offer=1`,
        cancel_url: window.location.origin + "/special_offer?canceled=true",
      }),
      redirect: "follow",
    })
      .then((data: { [key in string]: string }) => {
        if (data["url"]) {
          amplitude.track("show_checkout_screen");
          fbg("track", "Purchase", {
            currency: "USD",
            value: 19.99,
          });
          fbg("trackCustom", "purchase_success", {
            id: paymentData.productId,
          });
          document.location.href = data["url"];
        }
      })
      .catch((error) => {
        amplitude.track("purchase_failure", {
          error: error,
        });
      })
      .finally(() => {
        setIsLoad(false);
      });
  };

  return (
    <Layout>
      <a href={`${closeUrl}${queryParams}`} className={style.closeButton}>
        <img src={CloseIcon} alt="close" />
      </a>
      <div className={style.container}>
        <div className={style.content}>
          <div className={style.emoji}>🎉</div>
          <div className={style.offer}>
            <span className={style.offer_label}>50% OFF</span>
            <span className={style.offer_desc}>One time offer</span>
          </div>
          <CountDown />
        </div>
        <div className={style.footer}>
          <div className={style.discount}>
            <span className={style.discount_label}>
              $19.99 only / 12 months
            </span>
            <span className={style.discount_desc}> $39.99</span>
          </div>
          <div className={style.pay_desc}>
            You will be charged $19.99 / year by end of first year
          </div>
          <Button
            className={style.button}
            onClick={handlerClick}
            isDisabled={isLoad}
          >
            {isLoad ? <Circle /> : "Claim Now"}
          </Button>
        </div>
      </div>
      <Footer />
    </Layout>
  );
};
