const dev = [
  "price_1Q2ZTlPTLOkrCn0ed6wS5yWx",
  "price_1Q2ZX9PTLOkrCn0ecaD2rYQO",
];

const prod = [
  "price_1Q3IbXA3VB2ACp95wSD9wtXR",
  "price_1Q3IaxA3VB2ACp95CLvZJQOY",
];

export const getPaymentData = () =>
  process.env.REACT_APP_IS_PROD === "1" ? prod : dev;

export const productIds = [
  {
    productId: "prod_QuOJDWVs7a86L3",
    price: 6.99,
    dayPrice: 0.9,
    title: "Weekly",
  },
  {
    productId: "prod_QuOGTfNQQXr6B8",
    price: 39.99,
    dayPrice: 0.13,
    discount: 90,
    title: "Yearly",
  },
  {
    productId: "prod_RI1llWknx1YIGn",
    price: 19.99,
    dayPrice: 0.13,
    discount: 50,
    title: "Yearly",
  },
];
