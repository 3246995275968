import { useEffect, useRef } from "react";
import { useLocalStorage } from "usehooks-ts";

const useCountdown = (targetDate: number, onComplete?: () => void) => {
  const [timeRemaining, setTimeRemaining] = useLocalStorage(
    "timer_state",
    targetDate
  );

  const intervalRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    const start = () => {
      intervalRef.current = setInterval(() => {
        setTimeRemaining((prevTime) => {
          if (prevTime === 0) {
            intervalRef.current && clearInterval(intervalRef.current);
            onComplete && onComplete();
            start();
            return targetDate;
          } else {
            return prevTime - 1;
          }
        });
      }, 1000);
    };

    start();

    return () => {
      if (intervalRef.current) clearInterval(intervalRef.current);
    };
  }, []);

  const hours = Math.floor(timeRemaining / 3600);
  const minutes = Math.floor((timeRemaining % 3600) / 60);
  const seconds = timeRemaining % 60;

  return [hours, minutes, seconds];
};

export { useCountdown };
