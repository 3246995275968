import { Onboarding } from "./components/onboarding";
import { Paywall } from "./components/paywall";
import { SuccessPayment } from "./components/success-payment";
import { SpecialOffer } from './components/special-offer';
import * as amplitude from "@amplitude/analytics-browser";
import { useSetQueryParams } from './hooks/useSetQueryParams';

export const App = () => {
  amplitude.init(process.env.REACT_APP_AMPLITUDE_API_KEY as string, {
    logLevel: process.env.REACT_APP_IS_PROD === '1' ? 2 : 4,
    transport: 'beacon',
    flushIntervalMillis: 0,
    flushQueueSize: 1,
  });

  useSetQueryParams();

  if (["/paywall", "/paywall/"].includes(window.location.pathname)) {
    return <Paywall />;
  }

  if (["/success", "/success/"].includes(window.location.pathname)) {
    return <SuccessPayment />;
  }

  if (["/special_offer", "/special_offer/"].includes(window.location.pathname)) {
    return <SpecialOffer />;
  }

  return <Onboarding />;
};
